// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

// Styles
import './profile.scss'

const ProfilePicture = ({ image: { squared } }) => {
  return (
    <>
      <div className="home-profile__wrapper">
        <div className="home-profile">
          <div className="home-profile__image">
            <Img fixed={squared.fixed} className="squared" />
          </div>
        </div>
      </div>
    </>
  )
}

// Components PropTypes
ProfilePicture.propTypes = {
  image: PropTypes.shape({
    squared: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcWebp: PropTypes.string,
      srcSetWebp: PropTypes.string,
    }),
  }).isRequired,
}

export default ProfilePicture
