// Dependencies
import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Hero from '../components/Hero'
import ProfilePicture from '../components/ProfilePicture'
import Helmet from '../components/Helmet'
import Social from '../components/Social'

// Helpers
import { getNavigationProps, getExtraSections } from '../utils'

const HomePage = (props) => {
  const langLinks = [
    {
      url: props.path.replace(`/${props.pageContext.lang}`, ''),
      lang: props.pageContext.lang,
      active: true,
    },
    ...get(props, 'data.prismicHomeContent.alternate_languages'),
  ]

  const sections = [
    get(props, 'data.allPrismicGalleriesContent'),
    get(props, 'data.allPrismicTestimonialsContent'),
    get(props, 'data.allPrismicServicesContent'),
  ]

  const navigationProps = getNavigationProps(props)

  const followCopy = get(
    props,
    'data.allPrismicAboutContent.nodes[0].data.follow_copy.html',
    ''
  )

  const heroCopy = get(
    props,
    'data.prismicHomeContent.data.hero_copy.html',
    ''
  ).replace(/(<p\/?>)/gim, '')
  const heroImage = get(
    props,
    'data.prismicHomeContent.data.hero_image.document.data',
    false
  )
  const heroProps = {
    title: heroCopy,
    image: heroImage,
  }

  const profilePicture = get(
    props,
    'data.prismicHomeContent.data.profile_image.document.data',
    false
  )
  const profilePictureProps = {
    image: profilePicture,
  }

  const generalContent = get(
    props,
    'data.allPrismicGeneralContent.nodes[0].data',
    {}
  )

  const socialProps = {
    instagram: navigationProps.instagram,
    facebook: navigationProps.facebook,
    followCopy,
    latestPostsLabel: generalContent.latest_posts_label,
    loadMorePosts: generalContent.load_more_posts,
  }

  return (
    <Layout {...{ ...navigationProps, langLinks }}>
      <Helmet {...generalContent} />
      {heroProps.image && <Hero {...heroProps} />}
      {profilePictureProps && profilePictureProps.image && (
        <ProfilePicture {...profilePictureProps} />
      )}
      {getExtraSections(sections, generalContent, props.pageContext.lang)}
      <Social {...socialProps} />
    </Layout>
  )
}

export const indexPageQuery = graphql`
  query($id: String, $lang: String) {
    allPrismicNavigation(filter: { lang: { eq: $lang } }) {
      nodes {
        lang
        data {
          links {
            page {
              uid
              lang
            }
            label
          }
        }
      }
    }
    allPrismicGeneralContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          ...generalContent
        }
      }
    }
    allPrismicAboutContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          follow_copy {
            html
          }
        }
      }
    }
    prismicHomeContent(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        url
        lang
      }
      data {
        hero_copy {
          html
        }
        hero_image {
          document {
            ... on PrismicHeroImage {
              data {
                portrait {
                  fluid(maxWidth: 1650, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
                landscape {
                  fluid(maxWidth: 2800, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
              }
            }
          }
        }
        profile_image {
          document {
            ... on PrismicProfileImage {
              data {
                squared {
                  fixed(width: 300, height: 300, imgixParams: { q: 100 }) {
                    ...fixedImage
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicGalleriesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...galleriesContent
        }
      }
    }
    allPrismicServicesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...servicesContent
        }
      }
    }
    allPrismicTestimonialsContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        data {
          ...testimonialsContent
        }
      }
    }
  }
`

export default HomePage
